var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.innerUserRemark)?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"font-size-large padding padding-large layout-horizontal layout-top"},[_c('el-tooltip',{staticClass:"layout-inflexible",attrs:{"content":"标记完成"}},[_c('ys-checker',{staticClass:"layout-inflexible",staticStyle:{"margin-top":"3px"},attrs:{"value":_vm.innerUserRemark.finished},on:{"input":_vm.handleFinishedPicked}})],1),_c('div',{staticClass:"layout-flexible margin-left",staticStyle:{"overflow":"hidden"}},[_c('ys-click-to-edit',{staticClass:"font-weight-bold",style:(("color: " + (_vm.UserRemarkUtils.getUserRemarkColor(_vm.innerUserRemark.color)) + "; text-decoration: " + (_vm.innerUserRemark.finished ? 'line-through': '') + "; width: 100%;")),attrs:{"single-click-to-edit":"","type":"textarea"},on:{"input":_vm.handleContentInput},model:{value:(_vm.innerUserRemark.content),callback:function ($$v) {_vm.$set(_vm.innerUserRemark, "content", $$v)},expression:"innerUserRemark.content"}})],1)],1),_vm._m(0),_c('div',{staticClass:"setting-item layout-horizontal padding padding-large font-size-medium "},[_vm._m(1),_c('div',{staticClass:"layout-flexible"}),_c('div',{staticClass:"color-dot color-pink",class:{
              'is-active': _vm.innerUserRemark.color === _vm.UserRemarkColor.PINK,
           },style:(("background-color: " + (_vm.UserRemarkUtils.getUserRemarkColor(_vm.UserRemarkColor.PINK)))),on:{"click":function($event){return _vm.clickColor(_vm.UserRemarkColor.PINK)}}}),_c('div',{staticClass:"color-dot color-blue",class:{
              'is-active': _vm.innerUserRemark.color === _vm.UserRemarkColor.BLUE,
           },style:(("background-color: " + (_vm.UserRemarkUtils.getUserRemarkColor(_vm.UserRemarkColor.BLUE)))),on:{"click":function($event){return _vm.clickColor(_vm.UserRemarkColor.BLUE)}}}),_c('div',{staticClass:"color-dot color-green",class:{
              'is-active': _vm.innerUserRemark.color === _vm.UserRemarkColor.GREEN,
           },style:(("background-color: " + (_vm.UserRemarkUtils.getUserRemarkColor(_vm.UserRemarkColor.GREEN)))),on:{"click":function($event){return _vm.clickColor(_vm.UserRemarkColor.GREEN)}}})]),_c('div',{staticClass:"setting-item layout-horizontal padding padding-large font-size-medium",on:{"click":_vm.clickImportant}},[_c('div',{staticClass:"font-color-secondary"},[_c('i',{staticClass:"fas fa-star",class:_vm.innerUserRemark.important ? 'font-color-danger' : 'font-color-placeholder'}),_c('span',{staticClass:"margin-left"},[_vm._v("重点关注")])]),_c('div',{staticClass:"layout-flexible"}),_c('ys-switch',{staticClass:"layout-inflexible",attrs:{"value":_vm.innerUserRemark.important}})],1)]),_c('div',{staticClass:"card margin-top"},[_c('div',{staticClass:"setting-item layout-horizontal layout-middle padding padding-large font-size-medium",on:{"click":_vm.clickRemindMe}},[_vm._m(2),_c('div',{staticClass:"layout-flexible"}),(_vm.innerUserRemark.remindDate)?_c('div',{staticClass:"font-weight-bold font-size-large"},[_c('span',[_vm._v(_vm._s(_vm.TimeUtils.formatNormalDate(_vm.innerUserRemark.remindDate)))]),_c('el-tooltip',{attrs:{"content":"取消提醒"}},[_c('span',{staticClass:"padding btn-cancel-remind",on:{"click":function($event){$event.stopPropagation();return _vm.clickStopRemindMe($event)}}},[_c('i',{staticClass:"el-icon-close"})])])],1):_vm._e()]),_c('el-collapse-transition',[(_vm.showRemindPanel)?_c('div',[_c('div',{staticClass:"padding-horizontal-large"},[_c('div',{staticClass:"divider"})]),_c('div',{staticClass:"setting-item layout-horizontal padding padding-large font-size-medium font-color-secondary",on:{"click":_vm.clickRemindMeToday}},[_c('div',{class:{
              'is-active': _vm.innerUserRemark.remindDate && _vm.TimeUtils.isToday(_vm.innerUserRemark.remindDate)
              }},[_c('i',{staticClass:"far fa-sun"}),_c('span',{staticClass:"margin-left"},[_vm._v("今天")])])]),_c('div',{staticClass:"setting-item layout-horizontal padding padding-large font-size-medium font-color-secondary",on:{"click":_vm.clickRemindMeTomorrow}},[_c('div',{class:{
                'is-active': _vm.innerUserRemark.remindDate && _vm.TimeUtils.isTomorrow(_vm.innerUserRemark.remindDate)
              }},[_c('i',{staticClass:"fas fa-history"}),_c('span',{staticClass:"margin-left"},[_vm._v("明天")])])]),_c('div',{staticClass:"padding-horizontal-large"},[_c('div',{staticClass:"divider"})]),_c('div',{staticClass:"setting-item layout-horizontal padding padding-large font-size-medium",on:{"click":_vm.clickRemindMeByCalendary}},[_c('div',{staticClass:"font-color-secondary"},[_c('i',{staticClass:"far fa-calendar"}),_c('span',{staticClass:"margin-left"},[_vm._v("设置提醒日期")])])])]):_vm._e()])],1),_c('ys-dialog',{attrs:{"title":"设置提醒日期","visible":_vm.remindDatePickerDialogVisible},on:{"update:visible":function($event){_vm.remindDatePickerDialogVisible=$event}}},[_c('div',{staticClass:"layout-vertical layout-center"},[_c('calendar',{attrs:{"date":_vm.innerUserRemark.remindDate},on:{"change":_vm.handleRemindDateChange}})],1)])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding-horizontal-large"},[_c('div',{staticClass:"divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-color-secondary"},[_c('i',{staticClass:"fas fa-palette"}),_c('span',{staticClass:"margin-left"},[_vm._v("设置颜色")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-color-secondary"},[_c('i',{staticClass:"far fa-clock"}),_c('span',{staticClass:"margin-left"},[_vm._v("提醒我")])])}]

export { render, staticRenderFns }