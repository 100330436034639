<template>
  <div v-if="innerUserRemark">
    <div class="card">
      <div class="font-size-large padding padding-large layout-horizontal layout-top">
        <el-tooltip class="layout-inflexible" content="标记完成">
          <ys-checker
              class="layout-inflexible"
              style="margin-top: 3px;"
              :value="innerUserRemark.finished"
              @input="handleFinishedPicked"
          ></ys-checker>
        </el-tooltip>
        <div class="layout-flexible margin-left" style="overflow: hidden;">
          <ys-click-to-edit
              class="font-weight-bold"
              v-model="innerUserRemark.content"
              single-click-to-edit
              type="textarea"
              :style="`color: ${UserRemarkUtils.getUserRemarkColor(innerUserRemark.color)}; text-decoration: ${innerUserRemark.finished ? 'line-through': ''}; width: 100%;`"
              @input="handleContentInput"
          ></ys-click-to-edit>
        </div>
      </div>
      <div class="padding-horizontal-large">
        <div class="divider"></div>
      </div>
      <div class="setting-item layout-horizontal padding padding-large font-size-medium ">
        <div class="font-color-secondary">
          <i class="fas fa-palette"></i><span class="margin-left">设置颜色</span></div>
        <div class="layout-flexible"></div>
        <div class="color-dot color-pink"
             :class="{
                'is-active': innerUserRemark.color === UserRemarkColor.PINK,
             }"
             :style="`background-color: ${UserRemarkUtils.getUserRemarkColor(UserRemarkColor.PINK)}`"
             @click="clickColor(UserRemarkColor.PINK)"
        ></div>
        <div class="color-dot color-blue"
             :style="`background-color: ${UserRemarkUtils.getUserRemarkColor(UserRemarkColor.BLUE)}`"
             :class="{
                'is-active': innerUserRemark.color === UserRemarkColor.BLUE,
             }"
             @click="clickColor(UserRemarkColor.BLUE)"
        ></div>
        <div class="color-dot color-green"
             :style="`background-color: ${UserRemarkUtils.getUserRemarkColor(UserRemarkColor.GREEN)}`"
             :class="{
                'is-active': innerUserRemark.color === UserRemarkColor.GREEN,
             }"
             @click="clickColor(UserRemarkColor.GREEN)"
        ></div>
      </div>
      <div class="setting-item layout-horizontal padding padding-large font-size-medium"
           @click="clickImportant">
        <div class="font-color-secondary">
          <i
              class="fas fa-star"
              :class="innerUserRemark.important ? 'font-color-danger' : 'font-color-placeholder'"
          ></i><span class="margin-left">重点关注</span>
        </div>
        <div class="layout-flexible"></div>
        <ys-switch
            class="layout-inflexible"
            :value="innerUserRemark.important"
        ></ys-switch>
      </div>
    </div>
    <div class="card margin-top">
      <div class="setting-item layout-horizontal layout-middle padding padding-large font-size-medium"
           @click="clickRemindMe">
        <div class="font-color-secondary">
          <i class="far fa-clock"></i><span class="margin-left">提醒我</span>
        </div>
        <div class="layout-flexible"></div>
        <div class="font-weight-bold font-size-large" v-if="innerUserRemark.remindDate">
          <span>{{ TimeUtils.formatNormalDate(innerUserRemark.remindDate) }}</span>
          <el-tooltip content="取消提醒">
            <span class="padding btn-cancel-remind" @click.stop="clickStopRemindMe"><i class="el-icon-close"></i></span>
          </el-tooltip>
        </div>
      </div>
      <el-collapse-transition>
        <div v-if="showRemindPanel">
          <div class="padding-horizontal-large">
            <div class="divider"></div>
          </div>
          <div class="setting-item layout-horizontal padding padding-large font-size-medium font-color-secondary"
               @click="clickRemindMeToday">
            <div
                :class="{
                'is-active': innerUserRemark.remindDate && TimeUtils.isToday(innerUserRemark.remindDate)
                }"
            >
              <i class="far fa-sun"></i><span class="margin-left">今天</span>
            </div>
          </div>
          <div class="setting-item layout-horizontal padding padding-large font-size-medium font-color-secondary"
               @click="clickRemindMeTomorrow">
            <div
                :class="{
                  'is-active': innerUserRemark.remindDate && TimeUtils.isTomorrow(innerUserRemark.remindDate)
                }"
            >
              <i class="fas fa-history"></i><span class="margin-left">明天</span>
            </div>
          </div>
          <div class="padding-horizontal-large">
            <div class="divider"></div>
          </div>
          <div class="setting-item layout-horizontal padding padding-large font-size-medium"
               @click="clickRemindMeByCalendary">
            <div class="font-color-secondary">
              <i class="far fa-calendar"></i><span class="margin-left">设置提醒日期</span>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <ys-dialog
        title="设置提醒日期"
        :visible.sync="remindDatePickerDialogVisible"
    >
      <div class="layout-vertical layout-center">
        <calendar
            :date="innerUserRemark.remindDate"
            @change="handleRemindDateChange"
        ></calendar>
      </div>
    </ys-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsChecker from "@/components/wedigets/YsChecker";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import {UserRemarkColor, UserRemarkUtils} from "@/assets/javascript/user-remark-utils";
import YsSwitch from "@/components/wedigets/YsSwitch";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsDialog from "@/components/wedigets/YsDialog";
import Calendar from "@/components/calendar/Calendar";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import Utils from "@/assets/javascript/utils";

export default {
  name: "UserRemark",
  components: {Calendar, YsDialog, YsSwitch, YsClickToEdit, YsChecker},
  mixins: [httpapi, AutosubmitHelper],
  props: {
    userRemark: Object,
  },
  data() {
    return {
      /**
       * 常量
       */
      UserRemarkColor: UserRemarkColor,
      UserRemarkUtils: UserRemarkUtils,
      TimeUtils: TimeUtils,

      /**
       * 展开提醒我面板
       */
      showRemindPanel: false,

      /**
       * 选择提醒我的日期
       */
      remindDatePickerDialogVisible: false,

      /**
       * 内部用户标签
       */
      innerUserRemark: null,
    }
  },
  watch: {
    userRemark: {
      handler() {
        this.loadingCode++;
        this.innerUserRemark = Utils.deepCopy(this.userRemark);
        this.showRemindPanel = false;
        this.remindDatePickerDialogVisible = false;
      },
      immediate: true,
    }
  },
  methods: {
    handleContentInput() {
      this.createSubmitUserRemarkTask();
    },
    handleFinishedPicked(finished) {
      this.createSetUserRemarkFinishedTask(finished);
    },
    clickColor(color) {
      if (this.innerUserRemark.color === color) {
        this.innerUserRemark.color = UserRemarkColor.DEFAULT;
      } else {
        this.innerUserRemark.color = color;
      }
      this.createSubmitUserRemarkTask();
    },
    clickImportant() {
      this.innerUserRemark.important = !this.innerUserRemark.important;
      this.createSubmitUserRemarkTask();
    },
    clickRemindMe() {
      this.showRemindPanel = !this.showRemindPanel;
    },
    clickRemindMeToday() {
      this.innerUserRemark.remindDate = TimeUtils.format('yyyy-MM-dd', new Date());
      this.createSubmitUserRemarkTask();
    },
    clickRemindMeTomorrow() {
      this.innerUserRemark.remindDate = TimeUtils.format('yyyy-MM-dd', TimeUtils.plusDays(new Date(), 1));
      this.createSubmitUserRemarkTask();
    },
    clickRemindMeByCalendary() {
      this.remindDatePickerDialogVisible = true;
      this.createSubmitUserRemarkTask();
    },
    clickStopRemindMe() {
      this.innerUserRemark.remindDate = null;
      this.createSubmitUserRemarkTask();
    },
    handleRemindDateChange(date) {
      this.remindDatePickerDialogVisible = false;
      this.innerUserRemark.remindDate = TimeUtils.format('yyyy-MM-dd', date);
      this.createSubmitUserRemarkTask();
    },
    createSubmitUserRemarkTask() {
      let userRemark = this.innerUserRemark;
      this.createTask({
        tag: 'user_remark_' + userRemark.id,
        params: userRemark,
        runFunc: this.submitUserRemark,
      });
    },
    createSetUserRemarkFinishedTask(finished) {
      let userRemark = this.innerUserRemark;
      this.createTask({
        tag: 'user_remark_finished_' + userRemark.id + '#' + new Date().getTime(),
        params: {
          userRemark: this.innerUserRemark,
          finished,
        },
        runFunc: this.setUserRemarkFinished,
      });
    },
    async submitUserRemark(userRemark) {
      if (!userRemark.content) {
        if (userRemark.id === this.userRemark?.id) {
          userRemark.content = this.userRemark.content;
        } else {
          return;
        }
      }
      await this.$reqPut({
        path: `/user_remark/${userRemark.id}/${userRemark.important}/${userRemark.color}` + (userRemark.remindDate ? `/${userRemark.remindDate}` : ''),
        data: {
          content: userRemark.content,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.innerUserRemark = res.data;
            this.$emit('input', Utils.deepCopy(this.innerUserRemark));
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async setUserRemarkFinished({userRemark, finished}) {
      await this.$reqPatch({
        path: `/user_remark/${userRemark.id}/finished/${finished}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.innerUserRemark = res.data;
            this.$emit('input', Utils.deepCopy(this.innerUserRemark));
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }
  }
}
</script>

<style scoped>

.setting-item {
  cursor: pointer;
}

.setting-item:hover {
  background-color: #f8f8f8;
}

.is-active {
  color: #409EFF;
  font-weight: bold;
}

.color-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 5px;
  opacity: .3;
}

.color-dot:hover {
  opacity: .5;
}

.color-dot.is-active {
  opacity: 1;
}

.btn-cancel-remind {
  color: #bec0c0;
}

.btn-cancel-remind:hover {
  color: #ff5500;
}

.card {
  overflow: hidden;
}
</style>