<template>
  <div class="page layout-horizontal">
    <div class="layout-vertical layout-inflexible padding padding-small">
      <div class="list-item list-item-new layout-inflexible"
           @click="clickAddRemark"
      >
        <ys-click-to-edit
            ref="remarkInput"
            class="input-new-remark font-color-primary"
            placeholder="+ 添加新便签"
            placeholder-class="font-color-primary"
            :show-background="false"
            size="large"
            @keyup.native.enter="handleNewRemarkEnter"
            style="width: 100%"
            v-model="newRemarkInputted"
        ></ys-click-to-edit>
      </div>
      <div class="layout-flexible" style="overflow: hidden;">
        <ys-infinite-scroll-view style="height: 100%;">
          <div
              class="list-item layout-horizontal layout-middle padding-left-large padding-right-small padding-vertical"
              :class="{
                'is-remind': isRemind(userRemark),
              }"
              v-for="userRemark in userRemarkList"
              :key="userRemark.id"
              @click="clickUserRemark(userRemark)"
          >
            <el-tooltip class="layout-inflexible" content="标记完成">
              <ys-checker
                  class="layout-inflexible"
                  :value="userRemark.finished"
                  @input="handleFinishedPicked(userRemark, $event)"
              ></ys-checker>
            </el-tooltip>
            <div class="margin-left layout-flexible" style="overflow: hidden;">
              <div
                  class="user-remark-content font-size-medium font-nowrap"
                  :class="{
                    'is-finished': userRemark.finished,
                  }"
                  :style="`color: ${UserRemarkUtils.getUserRemarkColor(userRemark.color)}`"
                  style="width: 100%;"
              ><span v-if="userRemark.finished">[已完成]</span>{{ userRemark.content }}
              </div>
              <div class="font-color-secondary margin-top-small">
                <span
                    v-if="userRemark.remindDate"
                    :class="{
                      'font-color-danger': isRemind(userRemark),
                    }"
                ><i class="far fa-calendar font-color-placeholder"></i><span
                    class="margin-left">{{ TimeUtils.formatNormalDate(userRemark.remindDate) }}</span></span>
                <span v-else>无提醒</span>
              </div>
            </div>
            <div class="layout-inflexible font-size-medium padding padding-small"
                 @click.stop="clickImportant(userRemark)">
              <span class="font-color-danger" v-if="userRemark.important"><i class="fas fa-star"></i></span>
              <span class="font-color-placeholder" v-else><i class="far fa-star"></i></span>
            </div>
            <div class="layout-inflexible font-size-medium padding padding-small" @click.stop="clickDelete(userRemark)">
              <span class="font-color-placeholder"><i class="far fa-trash-alt"></i></span>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>

    </div>
    <div class="layout-flexible">
      <user-remark
          class="user-remark margin-vertical margin-right"
          :user-remark="currentUserRemark"
          @input="handleUserRemarkUpdate"
      ></user-remark>
    </div>
    <delete-dialog
        :visible.sync="deleteRemindDialogVisible"
        message="您确定要删除该用户标签吗？"
        @confirm="confirmDelete"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsInput from "@/components/wedigets/YsInput";
import httpapi from "@/assets/javascript/httpapi";
import {UserRemarkColor, UserRemarkUtils} from "@/assets/javascript/user-remark-utils";
import YsChecker from "@/components/wedigets/YsChecker";
import YsButton from "@/components/wedigets/YsButton";
import UserRemark from "@/pages/user/userremark/UserRemark";
import {TimeUtils} from "@/assets/javascript/time-utils";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";

export default {
  name: "UserRemarkListPanel",
  mixins: [httpapi],
  components: {YsClickToEdit, DeleteDialog, UserRemark, YsButton, YsChecker, YsInput, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {

      /**
       * 常量
       */
      UserRemarkColor: UserRemarkColor,
      UserRemarkUtils: UserRemarkUtils,
      TimeUtils: TimeUtils,

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 新便签输入
       */
      newRemarkInputted: null,

      /**
       * 用户便签列表
       */
      userRemarkList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 选择当前用户便签
       */
      currentUserRemark: null,

      /**
       * 删除用户标签
       */
      deleteRemindDialogVisible: false,
      deletingUserRemark: null,

    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.pageNum = 1;
        this.totalPages = 0;
        this.userRemarkList = [];
        this.currentUserRemark = null;
        if (this.userId && this.organizationId) {
          this.loadUserRemarkList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    clickAddRemark() {
      this.$refs.remarkInput.focus();
    },
    handleNewRemarkEnter() {
      this.addNewUserRemark(this.newRemarkInputted);
    },
    handleFinishedPicked(userRemark, finished) {
      this.setUserRemarkFinished(userRemark.id, finished);
    },
    clickUserRemark(userRemark) {
      this.currentUserRemark = userRemark;
    },
    clickImportant(userRemark) {
      this.setUserRemarkImportant(userRemark.id, !userRemark.important);
    },
    clickDelete(userRemark) {
      this.deletingUserRemark = userRemark;
      this.deleteRemindDialogVisible = true;
    },
    confirmDelete() {
      this.removeUserRemark(this.deletingUserRemark);
      this.deletingUserRemark = null;
    },
    handleUserRemarkUpdate(userRemark) {
      if (userRemark.userId === this.userId && userRemark.organizationId === this.organizationId) {
        this.$appendBefore(this.userRemarkList, [userRemark]);
      }
      this.notifyUserRemarkUpdate(userRemark);
    },
    isRemind(userRemark) {
      return userRemark.remindDate && !userRemark.finished && TimeUtils.daysBetween(userRemark.remindDate, new Date()) >= 0;
    },
    enterBusy() {
      if (this.busyLoadingCode === this.loadingCode) return false;
      this.busyLoadingCode = this.loadingCode;
      return true;
    },
    exitBusy() {
      this.busyLoadingCode = 0;
    },
    notifyUserRemarkUpdate(userRemark) {
      window.eventBus.$emit('update:user_remark', userRemark);
    },
    addNewUserRemark(content) {
      if (!this.enterBusy()) {
        this.$showBusy();
        return;
      }
      this.$reqPost({
        path: `/user_remark/${this.userId}/${this.organizationId}/${UserRemarkColor.DEFAULT}`,
        data: {
          content,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.newRemarkInputted = null;
            this.$appendBefore(this.userRemarkList, [res.data]);
            this.currentUserRemark = res.data;
            this.notifyUserRemarkUpdate(res.data);
          })
          .catch(() => this.$message.error('添加失败'))
          .complete(() => this.exitBusy());
    },
    setUserRemarkImportant(userRemarkId, important) {
      if (!this.enterBusy()) {
        this.$showBusy();
        return;
      }
      this.$reqPatch({
        path: `/user_remark/${userRemarkId}/important/${important}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$appendBefore(this.userRemarkList, [res.data]);
            if (this.currentUserRemark?.id === res.data.id) {
              this.currentUserRemark = res.data;
            }
            this.notifyUserRemarkUpdate(res.data);
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.exitBusy());
    },
    setUserRemarkFinished(userRemarkId, finished) {
      if (!this.enterBusy()) {
        this.$showBusy();
        return;
      }
      this.$reqPatch({
        path: `/user_remark/${userRemarkId}/finished/${finished}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let userRemark = res.data;
            this.$appendBefore(this.userRemarkList, [userRemark]);
            if (this.currentUserRemark?.id === userRemark.id) {
              this.currentUserRemark = userRemark;
            }
            this.notifyUserRemarkUpdate(res.data);
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.exitBusy());
    },
    removeUserRemark(userRemark) {
      if (!this.enterBusy()) {
        this.$showBusy();
        return;
      }
      this.$reqDelete({
        path: `/user_remark/${userRemark.id}`,
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.$message.success('删除成功');
            for (let n = this.userRemarkList.length - 1; n >= 0; n--) {
              if (this.userRemarkList[n].id === userRemark.id) {
                this.userRemarkList.splice(n, 1);
                break;
              }
            }
            if (this.currentUserRemark?.id === userRemark.id) {
              this.currentUserRemark = this.userRemarkList.length > 0 ? this.userRemarkList[0] : null;
            }
            this.notifyUserRemarkUpdate(userRemark);
          })
          .catch(() => this.$message.error('删除失败'))
          .complete(() => this.exitBusy());
    },
    loadUserRemarkList(pageNum = 1) {
      if (!this.enterBusy()) return;
      this.$reqGet({
        path: `/user_remark/list/${this.userId}/${this.organizationId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let userRemarkList = pageNum === 1 ? [] : this.userRemarkList;
            this.$appendAfter(userRemarkList, res.data.list);
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.userRemarkList = userRemarkList;
            if (!this.currentUserRemark && this.userRemarkList.length > 0) this.currentUserRemark = this.userRemarkList[0];
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.exitBusy());
    },
    loadMore() {
      if (this.pageNum < this.totalPages) this.loadUserRemark(this.pageNum + 1);
    },
    loadUserRemark(id) {
      this.$reqGet({
        path: `/user_remark/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let userRemark = res.data;
            if (userRemark.userId === this.userId && userRemark.organizationId === this.organizationId) {
              this.$appendBefore(this.userRemarkList, [userRemark]);
            }
          })
          .catch(() => this.$message.error('加载失败'));
    }
  }
}
</script>

<style scoped>

.list-item {
  width: 300px;
  box-sizing: border-box;
}

@keyframes anim-remind {
  0% {
    background-color: white;
  }
  50% {
    background-color: #f6bbc4;
  }
  100% {
    background-color: white;
  }
}

.list-item.is-remind {

  animation: anim-remind 3000ms 2;

}

.user-remark-content.is-finished {
  text-decoration: line-through;
  color: #909399;
}

.user-remark {
  max-width: 500px;
}

</style>