export const UserRemarkColor = {

    /**
     * 默认颜色
     */
    DEFAULT: 0,

    /**
     * 粉红色
     */
    PINK: 1,

    /**
     * 蓝色
     */
    BLUE: 2,

    /**
     * 绿色
     */
    GREEN: 3,
}

function getUserRemarkColor(colorInt) {
    switch (colorInt) {
        case UserRemarkColor.PINK:
            return "#ff3662";
        case UserRemarkColor.BLUE:
            return "#28a0ff";
        case UserRemarkColor.GREEN:
            return "#4dc476";
        default:
            return "#313131";
    }
}


export const UserRemarkUtils = {

    getUserRemarkColor,
}